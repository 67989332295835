import React from "react"

const Logo = () => {

    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 159.6 28.2"
        version="1.1"
        viewBox="0 0 159.6 28.2"
        xmlSpace="preserve"
        title="Square Owl"
        >
        <path d="M11.3 7.2c-.1-.7-.3-2-1.9-2-.9 0-1.8.6-1.8 1.6 0 1.2.6 1.5 4.3 3.2 3.9 1.7 4.8 3.5 4.8 5.9 0 3-1.7 6.4-7.2 6.4-6 0-7.5-3.9-7.5-6.6V15h5.3c0 2.4 1.5 2.8 2.2 2.8 1.3 0 2-1 2-2 0-1.4-.9-1.7-4.1-3.1-1.5-.6-5.1-2-5.1-5.9S6 .8 9.5.8c2 0 4.3.8 5.6 2.4 1.2 1.5 1.3 2.9 1.4 4.1h-5.2zM34.9 27.1h-4.6v-6.8h-.1c-.5.7-1.7 2.1-4.7 2.1-4.4 0-7.5-3.5-7.5-8 0-5.4 3.6-8.6 7.8-8.6 3 0 4.3 1.7 4.6 2.2h.1V6.3H35v20.8zm-8.4-9c2.4 0 3.9-2.1 3.9-3.9 0-1.7-1.1-4-3.9-4-2.5 0-3.9 2-3.9 4.1 0 1.5 1.3 3.8 3.9 3.8zM51.7 21.9h-4.4v-1.7h-.1c-1 2-2.9 2.2-4 2.2-1 0-3.2-.2-4.6-2-1.2-1.5-1.4-2.7-1.4-4.8V6.3h4.6v8.6c0 1.1 0 3.1 2.5 3.1 1.3 0 2.7-.7 2.7-3.2V6.3h4.6v15.6zM70.8 21.9h-4.6v-1.7h-.1c-.8 1.5-2.7 2.2-4.5 2.2-4.9 0-7.8-3.9-7.8-8.3 0-5.1 3.7-8.3 7.8-8.3 2.5 0 4 1.2 4.5 2.2h.1V6.3h4.6v15.6zm-8.6-3.8c2.9 0 3.9-2.5 3.9-4 0-1.9-1.3-3.9-3.9-3.9-2.7 0-3.8 2.2-3.8 4 0 1.4 1 3.9 3.8 3.9zM73.2 6.3h4.4V8h.1c.4-.8 1.2-2.2 3.9-2.2v4.7c-2.2.1-3.7.5-3.7 2.9v8.5h-4.6V6.3zM98 17c-.8 1.7-1.8 3.1-3.2 4-1.3.9-2.8 1.4-4.4 1.4-4.5 0-8.2-3.6-8.2-8.2 0-4.3 3.4-8.3 8.1-8.3s8.2 3.8 8.2 8.5c0 .6-.1.9-.1 1.2H86.8c.3 1.8 1.8 2.9 3.6 2.9 1.4 0 2.2-.6 2.8-1.5H98zm-4.1-4.6c-.2-.9-1.1-2.7-3.5-2.7s-3.3 1.7-3.5 2.7h7zM116.6.8c6.4 0 10.9 5 10.9 10.8s-4.5 10.8-11 10.8-10.9-5.2-10.9-10.5c0-1.4.2-2.9.8-4.4 1.2-2.8 4.4-6.7 10.2-6.7zm.1 16.8c3.8 0 5.6-3.4 5.6-6.1 0-2.7-1.8-6.1-5.6-6.1-3.7 0-5.7 3.1-5.7 6 0 3.3 2.1 6.2 5.7 6.2zM133 6.3l2.3 9.7h.1l2.5-9.7h3.9l2.6 9.7h.1l2.3-9.7h4.8l-4.8 15.5h-4.3l-2.5-10h-.1l-2.6 10.1H133l-4.8-15.5h4.8zM153.2 1.2h4.6v20.7h-4.6V1.2z"></path>
        </svg>
    )
}
  
export default Logo

