module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Square Owl","short_name":"squareowl","start_url":"/","background_color":"#111","theme_color":"#111","display":"minimal-ui","icon":"src/favicon/favicon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"21870337988cd8f678875951817ceb89"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KD9M68","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"squareowl"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/Users/darrenread/Sites/squareowl/gatsby-site/src/layouts/default.js"},
    }]
