import React, { Component } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Menu from "../components/menu"
import Logo from "../components/logo"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  toggleMenu() {
    this.setState({ open: !this.state.open })
    if (!this.state.open) {
      document.body.classList.add("menu-open")
    } else {
      document.body.classList.remove("menu-open")
    }
  }

  render() {
    return (
      <header className="c-header">
        <div className="u-flex u-flex--justify-content-space-bewteen">
          <AniLink
            paintDrip
            hex="#FF916F"
            to="/"
            className={"c-brand " + (this.state.open ? "menu-open" : "")}
          >
            <Logo />
          </AniLink>
          <Menu action={this.toggleMenu.bind(this)} open={this.state.open} />
        </div>
      </header>
    )
  }
}

export default Header
