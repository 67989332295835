import React, { Component } from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Link } from 'gatsby'

class Menu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      largeScreen: true,
      showClients: false,
      show: false,
    };
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ largeScreen: window.innerWidth > 768 });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  render() {
    const largeMenu = (
      <>
      <ul className="c-menu__pages">
          <li className="s-about"><AniLink paintDrip hex="#FF916F" to="/about" activeClassName="is-active">About Us</AniLink></li>
          <li><AniLink paintDrip hex="#FF916F" to="/clients" activeClassName="is-active">Clients</AniLink></li>
          <li className="s-case-study"><AniLink paintDrip hex="#FF916F" to="/case-studies/carfinance-247" activeClassName="is-active">Case Studies</AniLink></li>
          <li><AniLink paintDrip hex="#FF916F" to="/contact" activeClassName="is-active">Contact</AniLink></li>
      </ul>
      </>
    )

    const smallMenu = (
      <ul className="c-menu__pages">
        <li className="s-home"><Link to="/" onClick={this.props.action} onKeyDown={this.props.action} activeClassName="is-active">Home</Link></li>
        <li><Link to="/about" onClick={this.props.action} onKeyDown={this.props.action}  activeClassName="is-active">About Us</Link></li>
        <li><Link to="/clients" onClick={this.props.action} onKeyDown={this.props.action}  activeClassName="is-active">Clients</Link></li>
        <li><Link to="/case-studies/carfinance-247"  onClick={this.props.action} onKeyDown={this.props.action} activeClassName="is-active">Case Studies</Link></li>
        <li><Link to="/contact" onClick={this.props.action} onKeyDown={this.props.action}  activeClassName="is-active">Contact</Link></li>
      </ul>
    )

    return (
      <>
      <nav className="c-menu" role="navigation" aria-label="main navigation"> 
        <button
          className={
              "c-menu__toggle " + (this.props.open ? "is-active" : "")
          }
          aria-label="menu"
          aria-expanded="false"
          onClick={this.props.action}
          onKeyDown={this.props.action}
          >
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
            Menu
        </button>
        {this.state.largeScreen ? largeMenu : smallMenu}
      </nav>
      </>
    );
  }
}

export default Menu;


